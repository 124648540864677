export const paymentTypes = [
	{ id: 1, name: 'common.cash' },
	{ id: 2, name: 'common.onlinePayment' },
];

export const PROMOTION_TYPES = {
	'buy_1_get_1': {
		name: 'buy_1_get_1',
		nameTranslation: 'promotion.buy1Get1',
		value: '1+1'
	},
	'buy_2_get_2': {
		name: 'buy_2_get_2',
		nameTranslation: 'promotion.buy2Get2',
		value: '2+2'
	},
	'free_margherita': {
		name: 'free_margherita',
		nameTranslation: 'promotion.freeMargherita',
		value: '$mdiPizza',
		hasIcon: true
	},
	'special_discount': {
		name: 'special_discount',
		nameTranslation: 'promotion.specialDiscount',
		value: '%'
	},
	'special_price': {
		name: 'special_price',
		nameTranslation: 'promotion.specialPrice',
		value: '$mdiStar',
		hasIcon: true
	},
};

export const BOOLEAN_PARAMS = {
	false: 0,
	true: 1
}

export const nameOfRoutes = {
	aboutPage: 'AboutPage',
	orderPage: 'OrderPage',
	cartPage: 'Cart'
}
