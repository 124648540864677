<template>
	<AppLayout>
		<router-view></router-view>
		<v-dialog v-model="dialog" width="auto" persistent>
			<v-card max-width="500">
				<template #text>
					<div class="text-center">
						<div class="text-h6 text-center text-uppercase mb-4" style="color: #ffe4ab">
							<div class="mb-3"><v-icon>$mdiAlert</v-icon></div>
							{{ t('common.restaurantClosed') }}
						</div>
						<div>{{ t('common.welcomeMessage') }}</div>
					</div>
				</template>

				<template v-slot:actions>
					<div class="text-center w-full mb-4">
						<v-btn
							class="ms-auto px-6"
							:text="t('common.close')"
							variant="outlined"
							@click="dialog = false"
						></v-btn>
					</div>
				</template>
			</v-card>
		</v-dialog>
	</AppLayout>
</template>

<script setup>
import { ref, computed, onBeforeMount, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import i18n from '@/locale';
import { nameOfRoutes } from '@/utils/constant';
import { api, common } from '@/services';
import AppLayout from '@/layouts/App.vue';
import { useAuthStore } from '@/stores/auth';
import { useCartStore } from '@/stores/cart';

const route = useRoute();
const mainRouter = useRouter();
const authStore = useAuthStore();
const cartStore = useCartStore();

const isOrderPageAccessed = ref(false);
const dialog = ref(false);

const orders = computed({
	get: () => cartStore.orders,
	set: (val) => {
		cartStore.setOrders(val);
	},
});
const user = computed(() => authStore.user);
const openTime = computed({
	get: () => cartStore.openTime,
	set: (val) => {
		cartStore.openTime = val
	}
});

watch(
	route,
	(val) => {
		if (!openTime.value) {
			if (val.name === nameOfRoutes.cartPage) {
				dialog.value = true;
			} else if (
				val.name === nameOfRoutes.orderPage &&
				!isOrderPageAccessed.value
			) {
				dialog.value = true;
				isOrderPageAccessed.value = true;
			}
		}
	}, { deep: true }
);
/*-----functions------*/
const firstLoadPage = async () => {
	const openRes = await api.get(common.openTime);
	if (openRes) {
		const { isOpen } = openRes.data;
		if (!isOpen) {
			openTime.value = false;

			await mainRouter.isReady();
			if (route.name === nameOfRoutes.orderPage) {
				dialog.value = true;
				isOrderPageAccessed.value = true;
			}
		}
	}

	const res = await api.get(common.parameters);
	const data = res.data ||
		res || {
			shipping: 25000,
			vat: 0.1,
		};
	cartStore.setVAT(data.vat);
	cartStore.setShipping(data.shipping);

	// set orders
	if (user.value.id) {
		const res2 = await api.get(
			`${common.orders}?filter[customer]=${user.value.id}&order[createdAt]=desc`
		);
		orders.value = res2.data;
	}

	const meta = document.querySelector('meta[name="description"]');
	if (meta) meta.setAttribute('content', i18n.t('common.baietaMeta'));
	const metaH1 = document.querySelector('#h1-index');
	if (metaH1) metaH1.innerText = i18n.t('common.baietaMetaH1');
};
/*--------------------*/
onBeforeMount(() => {
	authStore.setLang(authStore.lang); // set once after mounted
});

onMounted(() => {
	firstLoadPage();
});
</script>
